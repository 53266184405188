import { DefaultValue, atom, selector } from "recoil";

import {
    ReportingDealerSizeModel,
    ReportingRetailerAreaModel,
    ReportingRetailerGroupModel,
} from "@loyaltylogistix/api-client";

export interface PlatformDealersAtom {
    isAreasLoading: boolean;
    isGroupsLoading: boolean;
    isSizesLoading: boolean;
    areas: ReportingRetailerAreaModel[];
    groups: ReportingRetailerGroupModel[];
    sizes: ReportingDealerSizeModel[];
    selected: string[];
}

export type AvailableDealerType<TType extends "areas" | "groups" | "sizes"> = {
    isLoading: boolean;
    available: PlatformDealersAtom[TType];
};

/**
 * @protected This atom should not be accessed directly. Use PlatformDealers, PlatformDealerAreas, PlatformDealerGroups, or PlatformSelectedDealers selectors instead.
 */
export const CorePlatformDealers = atom<PlatformDealersAtom>({
    key: "core::platform-dealers",
    default: {
        isAreasLoading: true,
        isGroupsLoading: true,
        isSizesLoading: true,
        areas: [],
        groups: [],
        sizes: [],
        selected: [],
    },
});

/**
 * @protected This atom should not be accessed directly. Use PlatformDealers, PlatformDealerAreas, PlatformDealerGroups, PlatformDealerSizes or PlatformSelectedDealers selectors instead.
 */
export const CorePlatformDealerAreas = selector<AvailableDealerType<"areas">>({
    key: "core::platform-dealer-areas",
    get: ({ get }) => {
        const { isAreasLoading: isLoading, areas: available } = get(CorePlatformDealers);
        return { isLoading, available };
    },
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformDealers, (prev) => ({ ...prev, isAreasLoading: true, areas: [] }));
            return;
        }
        set(CorePlatformDealers, (prev) => ({
            ...prev,
            isAreasLoading: newValue.isLoading,
            areas: newValue.available,
        }));
    },
});

/**
 * @protected This atom should not be accessed directly. Use PlatformDealers, PlatformDealerAreas, PlatformDealerGroups, PlatformDealerSizes or PlatformSelectedDealers selectors instead.
 */
export const CorePlatformDealerGroups = selector<AvailableDealerType<"groups">>({
    key: "core::platform-dealer-groups",
    get: ({ get }) => {
        const { isGroupsLoading: isLoading, groups: available } = get(CorePlatformDealers);
        return { isLoading, available };
    },
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformDealers, (prev) => ({ ...prev, isGroupsLoading: true, groups: [] }));
            return;
        }
        set(CorePlatformDealers, (prev) => ({
            ...prev,
            isGroupsLoading: newValue.isLoading,
            groups: newValue.available,
        }));
    },
});

/**
 * @protected This atom should not be accessed directly. Use PlatformDealers, PlatformDealerAreas, PlatformDealerGroups, PlatformDealerSizes or PlatformSelectedDealers selectors instead.
 */
export const CorePlatformDealerSizes = selector<AvailableDealerType<"sizes">>({
    key: "core::platform-dealer-sizes",
    get: ({ get }) => {
        const { isSizesLoading: isLoading, sizes: available } = get(CorePlatformDealers);
        return { isLoading, available };
    },

    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformDealers, (prev) => ({ ...prev, isSizesLoading: true, sizes: [] }));
            return;
        }
        set(CorePlatformDealers, (prev) => ({
            ...prev,
            isSizesLoading: newValue.isLoading,
            sizes: newValue.available,
        }));
    },
});

/**
 * @protected This atom should not be accessed directly. Use PlatformServiceDealers, PlatformServiceDealerAreas, PlatformServiceDealerGroups, PlatformServiceDealerSizes or PlatformSelectedServiceDealers selectors instead.
 */
export const CorePlatformServiceDealers = atom<PlatformDealersAtom>({
    key: "core::platform-service-dealers",
    default: {
        isAreasLoading: true,
        isGroupsLoading: true,
        isSizesLoading: true,
        areas: [],
        groups: [],
        sizes: [],
        selected: [],
    },
});

/**
 * @protected This atom should not be accessed directly. Use PlatformServiceDealers, PlatformServiceDealerAreas, PlatformServiceDealerGroups, PlatformServiceDealerSizes or PlatformSelectedServiceDealers selectors instead.
 */
export const CorePlatformServiceDealerAreas = selector<AvailableDealerType<"areas">>({
    key: "core::platform-service-dealer-areas",
    get: ({ get }) => {
        const { isAreasLoading: isLoading, areas: available } = get(CorePlatformServiceDealers);
        return { isLoading, available };
    },
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformServiceDealers, (prev) => ({
                ...prev,
                isAreasLoading: true,
                areas: [],
            }));
            return;
        }
        set(CorePlatformServiceDealers, (prev) => ({
            ...prev,
            isAreasLoading: newValue.isLoading,
            areas: newValue.available,
        }));
    },
});

/**
 * @protected This atom should not be accessed directly. Use PlatformServiceDealers, PlatformServiceDealerAreas, PlatformServiceDealerGroups, PlatformServiceDealerSizes or PlatformSelectedServiceDealers selectors instead.
 */
export const CorePlatformServiceDealerGroups = selector<AvailableDealerType<"groups">>({
    key: "core::platform-service-dealer-groups",
    get: ({ get }) => {
        const { isGroupsLoading: isLoading, groups: available } = get(CorePlatformServiceDealers);
        return { isLoading, available };
    },
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformServiceDealers, (prev) => ({
                ...prev,
                isGroupsLoading: true,
                groups: [],
            }));
            return;
        }
        set(CorePlatformServiceDealers, (prev) => ({
            ...prev,
            isGroupsLoading: newValue.isLoading,
            groups: newValue.available,
        }));
    },
});

/**
 * @protected This atom should not be accessed directly. Use PlatformServiceDealers, PlatformServiceDealerAreas, PlatformServiceDealerGroups, PlatformServiceDealerSizes or PlatformSelectedServiceDealers selectors instead.
 */
export const CorePlatformServiceDealerSizes = selector<AvailableDealerType<"sizes">>({
    key: "core::platform-service-dealer-sizes",
    get: ({ get }) => {
        const { isSizesLoading: isLoading, sizes: available } = get(CorePlatformServiceDealers);
        return { isLoading, available };
    },

    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformServiceDealers, (prev) => ({
                ...prev,
                isSizesLoading: true,
                sizes: [],
            }));
            return;
        }
        set(CorePlatformServiceDealers, (prev) => ({
            ...prev,
            isSizesLoading: newValue.isLoading,
            sizes: newValue.available,
        }));
    },
});

/**
 * @protected This atom should not be accessed directly. Use PlatformSalesDealers, PlatformSalesDealerAreas, PlatformSalesDealerGroups, PlatformSalesDealerSizes or PlatformSelectedSalesDealers selectors instead.
 */
export const CorePlatformSalesDealers = atom<PlatformDealersAtom>({
    key: "core::platform-sales-dealers",
    default: {
        isAreasLoading: true,
        isGroupsLoading: true,
        isSizesLoading: true,
        areas: [],
        groups: [],
        sizes: [],
        selected: [],
    },
});

/**
 * @protected This atom should not be accessed directly. Use PlatformSalesDealers, PlatformSalesDealerAreas, PlatformSalesDealerGroups, PlatformSalesDealerSizes or PlatformSelectedSalesDealers selectors instead.
 */
export const CorePlatformSalesDealerAreas = selector<AvailableDealerType<"areas">>({
    key: "core::platform-sales-dealer-areas",
    get: ({ get }) => {
        const { isAreasLoading: isLoading, areas: available } = get(CorePlatformSalesDealers);
        return { isLoading, available };
    },
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformSalesDealers, (prev) => ({ ...prev, isAreasLoading: true, areas: [] }));
            return;
        }
        set(CorePlatformSalesDealers, (prev) => ({
            ...prev,
            isAreasLoading: newValue.isLoading,
            areas: newValue.available,
        }));
    },
});

/**
 * @protected This atom should not be accessed directly. Use PlatformSalesDealers, PlatformSalesDealerAreas, PlatformSalesDealerGroups, PlatformSalesDealerSizes or PlatformSelectedSalesDealers selectors instead.
 */
export const CorePlatformSalesDealerGroups = selector<AvailableDealerType<"groups">>({
    key: "core::platform-sales-dealer-groups",
    get: ({ get }) => {
        const { isGroupsLoading: isLoading, groups: available } = get(CorePlatformSalesDealers);
        return { isLoading, available };
    },
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformSalesDealers, (prev) => ({
                ...prev,
                isGroupsLoading: true,
                groups: [],
            }));
            return;
        }
        set(CorePlatformDealers, (prev) => ({
            ...prev,
            isGroupsLoading: newValue.isLoading,
            groups: newValue.available,
        }));
    },
});

/**
 * @protected This atom should not be accessed directly. Use PlatformSalesDealers, PlatformSalesDealerAreas, PlatformSalesDealerGroups, PlatformSalesDealerSizes or PlatformSelectedSalesDealers selectors instead.
 */
export const CorePlatformSalesDealerSizes = selector<AvailableDealerType<"sizes">>({
    key: "core::platform-sales-dealer-sizes",
    get: ({ get }) => {
        const { isSizesLoading: isLoading, sizes: available } = get(CorePlatformSalesDealers);
        return { isLoading, available };
    },

    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformSalesDealers, (prev) => ({ ...prev, isSizesLoading: true, sizes: [] }));
            return;
        }
        set(CorePlatformSalesDealers, (prev) => ({
            ...prev,
            isSizesLoading: newValue.isLoading,
            sizes: newValue.available,
        }));
    },
});
