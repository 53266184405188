import { DefaultValue, selector } from "recoil";
import { CorePlatformDealers } from "../core/atoms";
import {
    CorePlatformSalesDealers,
    CorePlatformServiceDealers,
} from "../core/atoms/Core.PlatformDealers.atom";

export const PlatformDealers = selector({
    key: "public::platform-dealers",
    get: ({ get }) => get(CorePlatformDealers),
});

export const PlatformDealerAreas = selector({
    key: "public::platform-dealer-areas",
    get: ({ get }) => get(CorePlatformDealers).areas,
});

export const PlatformDealerGroups = selector({
    key: "public::platform-dealer-groups",
    get: ({ get }) => get(CorePlatformDealers).groups,
});

export const PlatformDealerSizes = selector({
    key: "public::platform-dealer-sizes",
    get: ({ get }) => get(CorePlatformDealers).sizes,
});

export const PlatformRetailerSites = selector({
    key: "public::retailer-sites",
    get: ({ get }) =>
        get(CorePlatformDealers).areas.flatMap(
            (area) => (area.retailerSites || [])?.map((site) => ({ ...site, area })) || []
        ),
});

export const PlatformRetailerSiteIds = selector({
    key: "public::retailer-site-ids",
    get: ({ get }) => get(PlatformRetailerSites).map((site) => site.retailerSiteId as string),
});

export const PlatformSelectedDealers = selector({
    key: "public::platform-selected-dealers",
    get: ({ get }) => get(CorePlatformDealers).selected,
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformDealers, (prev) => ({ ...prev, selected: [] }));
            return;
        }
        set(CorePlatformDealers, (prev) => ({ ...prev, selected: newValue }));
    },
});

export const PlatformServiceDealers = selector({
    key: "public::platform-service-dealers",
    get: ({ get }) => get(CorePlatformServiceDealers),
});

export const PlatformServiceDealerAreas = selector({
    key: "public::platform-service-dealer-areas",
    get: ({ get }) => get(CorePlatformServiceDealers).areas,
});

export const PlatformServiceDealerGroups = selector({
    key: "public::platform-service-dealer-groups",
    get: ({ get }) => get(CorePlatformServiceDealers).groups,
});

export const PlatformServiceDealerSizes = selector({
    key: "public::platform-service-dealer-sizes",
    get: ({ get }) => get(CorePlatformServiceDealers).sizes,
});

export const PlatformServiceRetailerSites = selector({
    key: "public::service-retailer-sites",
    get: ({ get }) =>
        get(CorePlatformServiceDealers).areas.flatMap(
            (area) => (area.retailerSites || [])?.map((site) => ({ ...site, area })) || []
        ),
});

export const PlatformServiceRetailerSiteIds = selector({
    key: "public::service-retailer-site-ids",
    get: ({ get }) =>
        get(PlatformServiceRetailerSites).map((site) => site.retailerSiteId as string),
});

export const PlatformSelectedServiceDealers = selector({
    key: "public::platform-selected-service-dealers",
    get: ({ get }) => get(CorePlatformServiceDealers).selected,
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformServiceDealers, (prev) => ({ ...prev, selected: [] }));
            return;
        }
        set(CorePlatformServiceDealers, (prev) => ({ ...prev, selected: newValue }));
    },
});

export const PlatformSalesDealers = selector({
    key: "public::platform-sales-dealers",
    get: ({ get }) => get(CorePlatformSalesDealers),
});

export const PlatformSalesDealerAreas = selector({
    key: "public::platform-sales-dealer-areas",
    get: ({ get }) => get(CorePlatformSalesDealers).areas,
});

export const PlatformSalesDealerGroups = selector({
    key: "public::platform-sales-dealer-groups",
    get: ({ get }) => get(CorePlatformSalesDealers).groups,
});

export const PlatformSalesDealerSizes = selector({
    key: "public::platform-sales-dealer-sizes",
    get: ({ get }) => get(CorePlatformSalesDealers).sizes,
});

export const PlatformSalesRetailerSites = selector({
    key: "public::sales-retailer-sites",
    get: ({ get }) =>
        get(CorePlatformSalesDealers).areas.flatMap(
            (area) => (area.retailerSites || [])?.map((site) => ({ ...site, area })) || []
        ),
});

export const PlatformSalesRetailerSiteIds = selector({
    key: "public::sales-retailer-site-ids",
    get: ({ get }) => get(PlatformSalesRetailerSites).map((site) => site.retailerSiteId as string),
});

export const PlatformSelectedSalesDealers = selector({
    key: "public::platform-selected-sales-dealers",
    get: ({ get }) => get(CorePlatformSalesDealers).selected,
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) {
            set(CorePlatformSalesDealers, (prev) => ({ ...prev, selected: [] }));
            return;
        }
        set(CorePlatformSalesDealers, (prev) => ({ ...prev, selected: newValue }));
    },
});
