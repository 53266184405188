import { PropsWithChildren } from "react";
import { useQuery } from "react-query";
import { SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    BrandClient,
    CampaignClient,
    MarketClient,
    PermissionClient,
    ReportingInputsClient,
} from "services/api.clients";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Params } from "global/tsHelpers";

import {
    CoreCampaignsDealerAreas,
    CoreCampaignsDealerGroups,
    CorePlatformActiveBrand,
    CorePlatformActiveMarket,
    CorePlatformAvailableBrands,
    CorePlatformAvailableMarkets,
    CorePlatformDealerAreas,
    CorePlatformDealerGroups,
    CorePlatformModels,
    CorePlatformUserPermissions,
    CorePlatformVehicleAgeGroups,
} from "../atoms";
import {
    AvailableDealerType,
    CorePlatformDealerSizes,
    CorePlatformSalesDealerAreas,
    CorePlatformSalesDealerGroups,
    CorePlatformSalesDealerSizes,
    CorePlatformServiceDealerAreas,
    CorePlatformServiceDealerGroups,
    CorePlatformServiceDealerSizes,
} from "../atoms/Core.PlatformDealers.atom";

const SharedQueryOptions = {
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: 1000 * 60 * 60 * 4, // 4 hours
    refetchInternalInBackground: true, // will refetch data if the tab is in the background
};

function usePlatformDealers(
    setDealerAreas: SetterOrUpdater<AvailableDealerType<"areas">>,
    setDealerGroups: SetterOrUpdater<AvailableDealerType<"groups">>,
    setDealerSizes: SetterOrUpdater<AvailableDealerType<"sizes">>,
    params: Params<typeof ReportingInputsClient.reportingInputsRetailerAreasGet>,
    isMarketsLoading: boolean,
    baseKey = "platform-dealer"
) {
    useQuery(
        [`${baseKey}:areas`, ...params] as const,
        ({ queryKey: [_, ...queryParams] }) =>
            ReportingInputsClient.reportingInputsRetailerAreasGet(...queryParams).then(
                (res) => res.data
            ),
        {
            onSuccess: (areas) => setDealerAreas({ isLoading: false, available: areas }),
            enabled: !isMarketsLoading,
            ...SharedQueryOptions,
        }
    );

    useQuery(
        [`${baseKey}:groups`, ...params] as const,
        ({ queryKey: [_, ...queryParams] }) =>
            ReportingInputsClient.reportingInputsRetailerGroupsGet(...queryParams).then(
                (res) => res.data
            ),
        {
            onSuccess: (groups) => setDealerGroups({ isLoading: false, available: groups }),
            enabled: !isMarketsLoading,
            ...SharedQueryOptions,
        }
    );

    useQuery(
        [`${baseKey}:sizes`, ...params] as const,
        ({ queryKey: [_, ...queryParams] }) =>
            ReportingInputsClient.reportingInputsDealerSizesGet(...queryParams).then(
                (res) => res.data
            ),
        {
            onSuccess: (sizes) => setDealerSizes({ isLoading: false, available: sizes }),
            enabled: !isMarketsLoading,
            ...SharedQueryOptions,
        }
    );
}

/**
 * Provider for platform constants - this is used to load the initial data required for the platform to function
 * such as brands, markets, models, permissions etc...
 */
export default function PlatformConstants({ children }: PropsWithChildren) {
    const setPermissions = useSetRecoilState(CorePlatformUserPermissions);

    const activeBrand = useRecoilValue(CorePlatformActiveBrand);
    const setAvailableBrands = useSetRecoilState(CorePlatformAvailableBrands);

    const [activeMarket, setActiveMarket] = useRecoilState(CorePlatformActiveMarket);
    const setAvailableMarkets = useSetRecoilState(CorePlatformAvailableMarkets);

    const setModels = useSetRecoilState(CorePlatformModels);
    const setVehicleAgeGroups = useSetRecoilState(CorePlatformVehicleAgeGroups);

    const setDealerAreas = useSetRecoilState(CorePlatformDealerAreas);
    const setDealerGroups = useSetRecoilState(CorePlatformDealerGroups);
    const setDealerSizes = useSetRecoilState(CorePlatformDealerSizes);

    const setServiceDealerAreas = useSetRecoilState(CorePlatformServiceDealerAreas);
    const setServiceDealerGroups = useSetRecoilState(CorePlatformServiceDealerGroups);
    const setServiceDealerSizes = useSetRecoilState(CorePlatformServiceDealerSizes);

    const setSalesDealerAreas = useSetRecoilState(CorePlatformSalesDealerAreas);
    const setSalesDealerGroups = useSetRecoilState(CorePlatformSalesDealerGroups);
    const setSalesDealerSizes = useSetRecoilState(CorePlatformSalesDealerSizes);

    const setCampaignsDealerAreas = useSetRecoilState(CoreCampaignsDealerAreas);
    const setCampaignsDealerGroups = useSetRecoilState(CoreCampaignsDealerGroups);

    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    useQuery(
        ["user-permissions"],
        () => PermissionClient.identityPermissionsCurrentUserGet().then((res) => res.data),
        {
            enabled: isAuthenticated,
            onSuccess: (permissions) => {
                if (permissions.length < 1) {
                    localStorage.clear();
                    instance.loginRedirect();
                }
                setPermissions(permissions as never[]);
            },
            ...SharedQueryOptions,
        }
    );

    /* The dealer areas call relies on the market being loaded - most times this will be the case immediately as activeMarket should be loaded
  form localStorage, however we should also ensure that the markets are loaded incase this isn't the case, or the user hasn't got access to the
  activeMarket stored in ls */
    const { isLoading: isMarketsLoading } = useQuery(
        ["platform-markets"],
        () => MarketClient.apiMarketsGet().then((res) => res.data),
        {
            onSuccess: (markets) => {
                setAvailableMarkets((prev) => ({ ...prev, isLoading: false, available: markets }));
                setActiveMarket(
                    (prev) => markets.find((market) => market.id === prev.id) || markets[0]
                );
            },
            ...SharedQueryOptions,
        }
    );

    useQuery(["platform-brands"], () => BrandClient.apiBrandsGet().then((res) => res.data), {
        onSuccess: (brands) =>
            setAvailableBrands((prev) => ({ ...prev, isLoading: false, available: brands })),
        ...SharedQueryOptions,
    });

    useQuery(
        ["platform-models"],
        () => ReportingInputsClient.reportingInputsModelsPost([]).then((res) => res.data),
        {
            onSuccess: (models: string[]) =>
                setModels((prev) => ({ ...prev, isLoading: false, available: models })),
            ...SharedQueryOptions,
        }
    );

    useQuery(
        ["platform-vehicle-age-groups"],
        () =>
            ReportingInputsClient.reportingInputsVehicleAgeGroupsGet(activeBrand?.id).then(
                (res) => res.data
            ),
        {
            onSuccess: (groups: { groupLabel: string; id: string }[]) =>
                setVehicleAgeGroups((prev) => ({ ...prev, isLoading: false, available: groups })),
            ...SharedQueryOptions,
        }
    );

    usePlatformDealers(
        setDealerAreas,
        setDealerGroups,
        setDealerSizes,
        [activeMarket?.id, activeBrand?.id, false, false],
        isMarketsLoading
    );

    usePlatformDealers(
        setServiceDealerAreas,
        setServiceDealerGroups,
        setServiceDealerSizes,
        [activeMarket?.id, activeBrand?.id, true, false],
        isMarketsLoading,
        "platform-service-dealer"
    );

    usePlatformDealers(
        setSalesDealerAreas,
        setSalesDealerGroups,
        setSalesDealerSizes,
        [activeMarket?.id, activeBrand?.id, false, true],
        isMarketsLoading,
        "platform-sales-dealer"
    );

    useQuery(
        ["campaigns-dealer-areas", activeMarket?.id, activeBrand?.id],
        ({ queryKey: [, marketId, brandId] }) =>
            CampaignClient.apiCampaignsRetailerAreasGet(marketId, brandId).then((res) => res.data),
        {
            onSuccess: (areas) => setCampaignsDealerAreas({ isLoading: false, available: areas }),
            enabled: !isMarketsLoading,
            ...SharedQueryOptions,
        }
    );

    useQuery(
        ["campaigns-dealer-groups", activeMarket?.id, activeBrand?.id],
        ({ queryKey: [, marketId, brandId] }) =>
            CampaignClient.apiCampaignsRetailerGroupsGet(marketId, brandId).then((res) => res.data),
        {
            onSuccess: (groups) =>
                setCampaignsDealerGroups({ isLoading: false, available: groups }),
            enabled: !isMarketsLoading,
            ...SharedQueryOptions,
        }
    );
    return children as JSX.Element;
}
